import React from 'react';
import { Link, graphql } from 'gatsby';

import {
  Container, Row, Col, Button,
} from 'reactstrap';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image'
import MainContent from '../components/mainContent';
import Jumbo from '../components/jumbo';
import Layout from '../components/Layout';

const IndexPage = ({ data }) => {
  
  return (
  
  <Layout>
    <HelmetDatoCms seo={data.datoCmsHomePage.seoMetaTags} />

    <Jumbo
      image={data.datoCmsHomePage.headerImage.fluid}
      title={data.datoCmsHomePage.mainHeading}
      subTitle={data.datoCmsHomePage.subHeading}
    />

    <MainContent content={data.datoCmsHomePage.mainText} />

    <Container className="narrow text-center">
      <Row>
        <Col>
          <h2>Acommodation designed to make you relax</h2>
        </Col>
      </Row>
      <Row className="py-4">
        {data.allDatoCmsAccomodationPage.edges.map(({ node }, index) => (
          <Col key={index} md="6" sm="12" className="mb-4 highlight">
            <Img fluid={node.headerImage.fluid} />
            <h2 className="mt-3">
              {' '}
              {node.mainHeading}
            </h2>
            <p className="px-3">{node.introText}</p>
            <p>
              <Link to={`/byron-bay-accommodation/${node.slug}`}>
                <Button outline color="primary">
                  Learn more
                </Button>
              </Link>
            </p>
          </Col>
        ))}
      </Row>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
        {
          "@context": "http://schema.org",
          "@type": "Hotel",
          "name" : "Garden Burees",
          "description" : "This tucked away treasure, nestled in tropical surrounds is only a short stroll (10 minutes) to the famous beaches, shops, markets and restaurants of Byron. Located next door to The Garden Burees is a treasure trove of enjoyment with The Buddha Gardens Day Spa, The Iconic Arts Factory Lodge, The Arthouse Lounge Cinema and Byron Bay Brewery.",
          "address" : { "@type" : "PostalAddress",
                       "addressCountry" : "Australia",
                       "addressLocality" : "Byron Bay",
                       "addressRegion" : "NSW",
                       "postalCode" : "2481",
                       "streetAddress" : "17 Gordon Street"
                       },
          "telephone" :  "+61 02 6685-5390",
          "image": "https://www.datocms-assets.com/6201/1530641741-garden-buree-outside.jpg"
        } `,
        }}
      />
    </Container>
  </Layout>
)};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    datoCmsHomePage {
      mainHeading
      subHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainText
      headerImage {
        fluid(maxWidth: 941, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    allDatoCmsAccomodationPage {
      edges {
        node {
          mainHeading
          slug
          introText
          headerImage {
            fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
